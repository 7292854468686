import React from 'react';
import { Route, Routes, } from 'react-router-dom';

import LoginComponent from './LoginComponent';
import { SamplePackageTracking } from './samplePackageTracking';
import { ServiceHeader } from './components';

import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { clearEmailToken, clearAccessToken } from './services/User';

const AppWrapper = () => {

    return (

        <div style={{ background: "rgb(240,242,244", height: "100vh" }}>
            <ServiceHeader
                needsLogOut={true}
                menuItems={[{
                    url: "",
                    title: ""
                    // url: "https://intranet.servitech.com",
                    // title: "Return to Intranet"
                }]}
                title={"Sample Package Tracking"}
            />
            <div className="content-routes">
                <Routes>
                    <Route path="login" element={<LoginComponent />} />
                    <Route exact path="/" element={<SamplePackageTracking />} />
                </Routes>
            </div>
        </div>
    );
}

export default AppWrapper;
